<template>
  <div>
    <!-- eslint-disable -->
    <!--    Top    -->
    <div class="d-flex justify-content-between mb-1">
      <div class="d-flex" style="column-gap: .5rem; row-gap: .5rem;">
        <!--    Quit    -->
        <b-button
          variant="secondary"
          size="sm"
          :to="{ name: 'shop' }"
        >
          {{ $t('Cancel') }}
        </b-button>

        <!--    Save button    -->
        <b-button
          v-if="checkRequiredPermissions([$roles.SHOP_PRODUCT_MODIFY])"
          variant="primary"
          size="sm"
          :disabled="saveLoading"
          @click="pushToServer"
        >
          {{ $t('Save') }}
        </b-button>
      </div>
      <div v-if="allowEditButton && checkRequiredPermissions([$roles.SHOP_PRODUCT_MODIFY])">
        <b-button
          size="sm"
          class="mr-25"
          variant="danger"
          @click="deleteProduct"
        >
          {{ $t('Delete') }}
        </b-button>

        <b-button
          size="sm"
          :variant="isEdited ? 'warning' : 'success'"
          @click="isEdited = !isEdited"
        >
          {{ isEdited ? $t('Edit') : $t('Preview') }}
        </b-button>
      </div>
    </div>

    <!--    Form    -->
    <validation-observer
      ref="observer"
      tag="form"
    >
        <b-card no-body>
            <sw-select :disabled="isEdited" class="mb-0 pb-0">
                <v-select
                    v-model.trim="selectedLocale"
                    :options="filteredLocales"
                    :reduce="e => e.value"
                    class="mb-0"
                    label="name"
                    @option:selected="checkAvailableLocale"
                >
                    <template #option="{ label }">
                        {{ $t(label) }}
                    </template>
                    <template #selected-option="{ label }">
                        {{ $t(label) }}
                    </template>
                </v-select>
            </sw-select>
        </b-card>
      <b-tabs>
        <!--    Basic information     -->
        <b-tab
          :title="$t('BasicInformation')"
        >
          <b-card>
            <b-overlay
              no-wrap
              :show="loading"
              spinner-variant="primary"
            />
            <!--     Section: Bindings     -->
            <b-row>
              <!--     Name     -->
              <b-col
                sm="12"
                md="6"
                lg="4"
              >
                <validation-provider
                  #default="{ errors }"
                  :name="$t('offer.product.name')"
                  rules="required"
                >
                  <b-form-group :label="$t('offer.product.name')">
                    <b-form-input
                      :value="selectedTranslationObject.name"
                      @input="setTranslationValue($event, 'name')"
                      :placeholder="$t('offer.product.name')"
                      :state="errors.length > 0 ? false:null"
                      :disabled="isEdited"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </b-form-group>
                </validation-provider>
              </b-col>

              <!--     Code     -->
              <b-col
                sm="12"
                md="6"
                lg="4"
              >
                <validation-provider
                  #default="{ errors }"
                  :name="$t('offer.product.code')"
                  rules="required"
                >
                  <b-form-group :label="$t('offer.product.code')">
                    <b-form-input
                      v-model.trim="newProduct.code"
                      :placeholder="$t('offer.product.code')"
                      :state="errors.length > 0 ? false:null"
                      :disabled="isEdited"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </b-form-group>
                </validation-provider>
              </b-col>

              <!--    Active    -->
              <b-col
                sm="12"
                md="6"
                lg="4"
                class="d-flex"
              >
                <b-form-group :label="$t('RankingProductColor')">
                  <b-form-input
                    v-model="newProduct.color"
                    type="color"
                  />
                </b-form-group>

                <b-form-group class="ml-1" :label="$t('isActive')">
                  <b-form-checkbox
                    v-model="newProduct.active"
                    class="custom-control-primary"
                    :disabled="isEdited"
                    variant="primary"
                  >
                    {{ $t('offer.product.active') }}
                  </b-form-checkbox>
                </b-form-group>
              </b-col>
            </b-row>

            <!--     Section: Categories     -->
            <b-row class="my-1">
              <!--     Group     -->
              <b-col
                sm="12"
                md="6"
                lg="4"
              >
                <!--    Select list    -->
                <validation-provider
                  #default="{ errors }"
                  :name="$t('offer.Group')"
                >
                  <b-form-group :label="$t('offer.Group')">
                    <sw-select :disabled="isEdited">
                      <v-select
                        v-model.trim="newProduct.userGroup"
                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                        :placeholder="$t('offer.Group')"
                        :options="groups"
                        :disabled="isEdited"
                        label="name"
                        @input="selectGroup"
                      >
                        <template #option="{ name, color }">
                          <div
                            class="color-dot"
                            style="border: 1px solid white"
                            :style="{ backgroundColor: color }"
                          />
                          {{ name }}
                        </template>
                        <template #selected-option="{ name, color }">
                          <div
                            class="color-dot"
                            style="border: 1px solid white"
                            :style="{ backgroundColor: color }"
                          />
                          {{ name }}
                        </template>
                        <template #no-options>
                          {{ $t('NoOptions') }}
                        </template>
                      </v-select>
                    </sw-select>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </b-form-group>
                </validation-provider>
              </b-col>

              <!--     Category     -->
              <b-col
                sm="12"
                md="6"
                lg="4"
              >
                <!--    Select list    -->
                <validation-provider
                  #default="{ errors }"
                  :name="$t('offer.product.category')"
                  rules="required"
                >
                  <b-form-group :label="$t('offer.product.category')">
                    <sw-select :disabled="isEdited">
                      <v-select
                        v-model.trim="newProduct.category"
                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                        :placeholder="$t('offer.product.category')"
                        :options="categoriesByGroup"
                        label="id"
                        :disabled="isEdited"
                        @input="selectCategory"
                      >
                        <template #option="item">
                          {{ item.translations[0].name }}
                        </template>
                        <template #selected-option="item">
                          {{ item.translations[0].name }}
                        </template>
                        <template #no-options>
                          {{ $t('NoOptions') }}
                        </template>
                      </v-select>
                    </sw-select>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </b-form-group>
                </validation-provider>
              </b-col>

              <!--     Subcategory     -->
              <b-col
                sm="12"
                md="6"
                lg="4"
              >
                <!--    Select list    -->
                <validation-provider
                  #default="{ errors }"
                  :name="$t('offer.product.subcategory')"
                >
                  <b-form-group :label="$t('offer.product.subcategory')">
                    <sw-select :disabled="isEdited || !subcategories.length">
                      <v-select
                        v-model.trim="newProduct.shopCategories"
                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                        :placeholder="$t('offer.product.subcategory')"
                        :options="subcategories"
                        label="id"
                        :disabled="isEdited || !subcategories.length"
                        @input="selectSubCategory"
                      >
                        <template #option="item">
                          {{ item.translations[0].name }}
                        </template>
                        <template #selected-option="item">
                          {{ item.translations[0].name }}
                        </template>
                        <template #no-options>
                          {{ $t('NoOptions') }}
                        </template>
                      </v-select>
                    </sw-select>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </b-form-group>
                </validation-provider>
              </b-col>
            </b-row>

            <!--     Section: Other     -->
            <b-row class="my-1">
              <!--     Group     -->
              <b-col
                  sm="12"
                  md="6"
                  lg="4"
              >
                <!--    Unit    -->
                  <sw-select
                    :name="$t('Unit')"
                    :disabled="isEdited"
                  >
                    <v-select
                      v-model.trim="newProduct.amountUnitType"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      :placeholder="$t('Unit')"
                      :options="units"
                      :disabled="isEdited"
                      :get-option-label="name => $t(`unit.${name}`)"
                      @input="$forceUpdate()"
                    />
                  </sw-select>
              </b-col>
            </b-row>
            <!-- Local -->
          </b-card>

          <b-card>
            <b-overlay
              no-wrap
              :show="loading"
              spinner-variant="primary"
            />
            <h4 class="mb-1">
              {{ $t('offer.product.price') }}
            </h4>
            <b-row>
              <b-col
                sm="4"
                md="3"
              >
                <sw-select name="Vat">
                  <v-select
                    v-model="newProduct.vat"
                    :disabled="isEdited"
                    :options="[0, 8, 23]"
                    class="mr-50"
                    :clearable="false"
                  />
                </sw-select>
              </b-col>
              <b-col
                sm="8"
                md="4"
              >
                <b-form-group :label="$t('offer.product.priceNet')">
                  <validation-provider
                    #default="{ errors }"
                    :name="$t('contact.phone')"
                    rules="required"
                  >
                    <cleave
                      v-model="newProduct.offerPrice"
                      :options="cleaveOptions.number"
                      class="form-control"
                      :disabled="isEdited"
                      :state="errors.length > 0 ? false:null"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col
                sm="12"
                md="5"
              >
                <b-form-group :label="$t('offer.product.priceGross')">
                  <cleave
                    :options="cleaveOptions.number"
                    class="form-control"
                    :value="calculateOfferGross"
                    disabled
                  />
                </b-form-group>
              </b-col>
            </b-row>
            <!--                                :value="(newProduct.offerPrice * ((newProduct.vat / 100) + 1)).toFixed(2)"-->
            <!--     Section: Description     -->
            <!--            <h4 class="mb-1">-->
            <!--              {{ $t('Offer.Discount') }}-->
            <!--              <b-form-checkbox-->
            <!--                v-model="newProduct.shopProductDiscounts[0].type"-->
            <!--                checked="false"-->
            <!--                class="ml-25"-->
            <!--                name="check-button"-->
            <!--                switch-->
            <!--                inline-->
            <!--                :disabled="isEdited"-->
            <!--                @change="newProduct.shopProductDiscounts[0].value = ''"-->
            <!--              >-->
            <!--                <span class="switch-icon-left">-->
            <!--                  <feather-icon icon="DollarSignIcon" />-->
            <!--                </span>-->
            <!--                <span class="switch-icon-right">-->
            <!--                  <feather-icon icon="PercentIcon" />-->
            <!--                </span>-->
            <!--              </b-form-checkbox>-->
            <!--            </h4>-->

            <b-row>
              <b-col sm="12">
                <h4>
                  <span class="pt-n1">
                    {{ $t('Offer.Discount') }}
                  </span>
                  <b-form-checkbox
                    v-model="newProduct.shopProductDiscounts[0].type"
                    checked="false"
                    class="ml-25"
                    name="check-button"
                    variant="primary"
                    switch
                    inline
                    :disabled="isEdited"
                    @change="newProduct.shopProductDiscounts[0].value = ''"
                  >
                    <span class="switch-icon-left">
                      <feather-icon icon="DollarSignIcon" />
                    </span>
                    <span class="switch-icon-right">
                      <feather-icon icon="PercentIcon" />
                    </span>
                  </b-form-checkbox>
                </h4>
              </b-col>
              <b-col
                sm="12"
                md="6"
                lg="4"
              >
                <!--    Description    -->
                <b-form-group :label="$t('Value')">
                  <cleave
                    v-model="newProduct.shopProductDiscounts[0].value"
                    :options="cleaveOptions.number"
                    class="form-control"
                    :disabled="isEdited"
                  />
                </b-form-group>
              </b-col>
              <b-col
                sm="12"
                md="6"
                lg="4"
              >
                <!--    Description    -->
                <b-form-group :label="$t('Costs.DateRange')">
                  <sw-select :disabled="isEdited">
                    <sw-select-fpr
                      :is-empty="timeRange"
                      @clear="timeRange = ''"
                    >
                      <flat-pickr
                        ref="timeRange"
                        v-model="timeRange"
                        class="form-control mr-50"
                        :config="{ ...flatPickerConfig, locale: getCalendarLocale($i18n.locale) }"
                        :disabled="isEdited"
                        @input="onChangeDate"
                      />
                    </sw-select-fpr>
                  </sw-select>
                </b-form-group>
              </b-col>
            </b-row>
          </b-card>

          <b-card class="mb-1">
            <b-overlay
              no-wrap
              :show="loading"
              spinner-variant="primary"
            />
            <!--     Section: Description     -->
            <!--    Description    -->
            <b-form-group :label="$t('offer.product.description')">
              <quill-editor
                :value="selectedTranslationObject.description"
                @input="setTranslationValue($event, 'description')"
                :options="quillOptions"
                :disabled="isEdited"
              />
            </b-form-group>

            <b-form-group :label="$t('OutsideShopUrl')">
                <b-input-group class="input-group-merge">
                    <b-input-group-prepend :is-text="true">
                        <feather-icon icon="GlobeIcon" />
                    </b-input-group-prepend>
                    <b-form-input :value="selectedTranslationObject.webUrl" @input="setTranslationValue($event, 'webUrl')" />
                </b-input-group>
            </b-form-group>
          </b-card>
        </b-tab>

        <!-- Storage -->
        <b-tab>
          <template #title>
            {{ $t('Storage') }}
            <b-badge
              class="ml-25"
              variant="light-primary"
            >
              {{ currentProductStorageAmount }}
            </b-badge>
          </template>

          <b-alert
            show
            class="p-75 d-flex justify-content-between align-items-center"
            variant="primary"
          >
            <div>{{ $t('CurrentStorageAmount') }}: <strong>{{ currentProductStorageAmount }}</strong></div>
            <b-button
              :disabled="isEdited"
              variant="primary"
              size="sm"
              @click="showStorageModal = true"
            >{{ $t('Change') }}</b-button>
          </b-alert>
          <b-card>
            <sw-table
              table-id="c068a9e3250d4304b48e5ca96bda0dd0"
              :pagination="storagePagination"
              @change-pagination="Object.assign(storagePagination, $event)"
              @reload-content="loadStorage"
            >
              <template #table>
                <b-table
                  :items="productStorageItems"
                  :fields="storageFields"
                  :busy="storageLoading"
                  striped
                  responsive
                  hover
                >
                  <!--    Table Head    -->
                  <template #head()="{ label }">
                    {{ $t(`${label}`) }}
                  </template>
                  <!-- -->
                  <template #cell(amount)="{ value }">
                    <b-badge
                      variant="light-primary"
                      style="width: 65px"
                    >
                      {{ value }}
                    </b-badge>
                  </template>
                  <template #cell(amount)="{ value }">
                    <b-badge
                      variant="light-primary"
                      style="width: 65px"
                    >
                      {{ value }}
                    </b-badge>
                  </template>
                  <!-- -->
                  <template #cell(files)="{ value }">
                    <documents-list
                      v-if="Array.isArray(value) && value.length"
                      :documents="value"
                    />
                    <feather-icon
                      v-else
                      icon="MinusIcon"
                    />
                  </template>
                  <template #cell(createdBy)="{ value }">
                    <avatar :user="value" />
                  </template>
                    <!--    Other    -->
                    <template #cell()="row">
                        <table-default-cell
                                :field="row.field"
                                :value="row.value"
                        />
                    </template>

                    <!--      Empty      -->
                    <template #empty>
                        <empty-content />
                    </template>

                    <!--      Table Busy      -->
                    <template #table-busy>
                        <div class="text-center text-danger my-2">
                            <b-spinner
                                    class="align-middle"
                                    variant="primary"
                            />
                        </div>
                    </template>
                </b-table>
              </template>
            </sw-table>
          </b-card>

          <b-modal
            v-model="showStorageModal"
            :title="$t('Storage')"
            size="md"
            hide-footer
            no-close-on-backdrop
            @close="closeModal"
          >
            <b-form-group>
              <div class="d-flex align-items-center">
                <b-form-checkbox
                  v-model="storageItem.removeFromStorage"
                  name="check-button"
                  variant="primary"
                  switch
                  inline
                />

                <span>{{ $t(storageItem.removeFromStorage ? 'RemoveFromStorage' : 'AddToStorage') }}</span>
              </div>
            </b-form-group>

            <b-form-group :label="$t('Offer.Amount')">
              <b-input-group class="input-group-merge">
                <b-input-group-prepend is-text>
                  <feather-icon :icon="storageItem.removeFromStorage ? 'MinusIcon' : 'PlusIcon'" />
                </b-input-group-prepend>
                <b-form-input
                  v-model="storageItem.amount"
                  placeholder="0"
                  type="number"
                />
              </b-input-group>
            </b-form-group>
            <b-form-group :label="$t('Note')">
              <b-form-textarea v-model="storageItem.note" />
            </b-form-group>
            <b-form-group>
              <DragDropUpload
                :value="storageItem.files"
                :disabled="isEdited"
                accept="application/pdf"
                :mt="false"
                :tname="`files_storage`"
                @onChangeFiles="changeStorageFiles($event)"
              />
            </b-form-group>
            <b-button
              size="sm"
              variant="primary"
              :disabled="!storageItem.amount"
              @click="addToStorage"
            >
              {{ $t('Add') }}
            </b-button>
          </b-modal>
        </b-tab>

        <!--    Constant attributes     -->
        <b-tab
          :title="$t('offer.product.constantAttributes')"
        >
          <!--    Button: New Constant attributes    -->
          <b-button
            :disabled="isEdited"
            class="mb-1"
            variant="primary"
            size="sm"
            @click="openAttributeModal({value: '',
                                        visibleOfferWWW: false,
                                        valueShopAttributeOptions: [],
                                        active: true,})"
          >
            {{ $t('Add') }}
          </b-button>

          <b-card class="cardTable">
            <b-overlay
              no-wrap
              :show="loading"
              spinner-variant="primary"
            />
            <const-attribute-table
              :attributes="attributes"
              :is-edited="isEdited"
              @editAttribute="openAttributeModal"
              @clone-attribute="openAttributeModal"
              @remove-attribute="deleteAttributeModal"
            />
          </b-card>
        </b-tab>

        <!--    Pictures     -->
        <b-tab :title="$t('offer.product.pictures')">
          <div v-if="Array.isArray(pictureTypes) && pictureTypes.length">
            <b-card
              v-for="type in pictureTypes"
              :key="`picture_type_${type.id}`"
            >
              <h4 class="mb-1">
                <feather-icon
                  v-if="type.visibleOfferWWW"
                  v-b-tooltip
                  icon="EyeIcon"
                  class="text-primary mr-25"
                  size="20"
                  :title="$t('ImagesWillBeVisibleOnWebsite')"
                />
                {{ type.name }}
              </h4>

              <draggable
                v-model="type.pictures"
                class="row px-50 pb-50"
                :options="{ disabled: isEdited }"
              >
                <b-list-group-item
                  v-for="(image, index) in type.pictures"
                  :key="index"
                  class="col-md-4 col-lg-3 cursor-move d-flex border-0 p-50"
                  @mouseenter="loadImage(image)"
                >
                  <div class="image-overlay-container border rounded">
                    <b-img
                      v-if="image && image.preview && image.preview.includes('http')"
                      class="rounded"
                      style="height: 250px; object-fit: cover; width: 100%; "
                      :src="image.preview"
                    />
                    <b-img
                      v-else
                      class="rounded"
                      style="height: 250px; object-fit: cover; width: 100%; "
                      :src="`data:${image.type && image.type ? image.type : image.extension};base64,${image.preview}`"
                    />

                    <b-button
                      class="btn-icon rounded-circle cursor-pointer position-absolute position-top-0 position-right-0 m-50"
                      size="sm"
                      variant="danger"
                    >
                      <feather-icon
                        size="20"
                        icon="TrashIcon"
                        @click="$delete(type.pictures, index)"
                      />
                    </b-button>
                  </div>
                </b-list-group-item>
              </draggable>

              <b-row>
                <b-col
                  sm="12"
                  md="12"
                  lg="12"
                >
                  <b-form-group>
                    <DragDropUpload
                      :disabled="isEdited"
                      accept="image/*"
                      :show-files="false"
                      :mt="false"
                      :tname="`pictures_${type.id}`"
                      @onChangeFiles="changePictureFiles('pictures', type.id, $event)"
                    />
                  </b-form-group>
                </b-col>
              </b-row>
            </b-card>
          </div>
          <b-card v-else>
            <div class="d-flex align-items-center flex-column">
              <div class="text-primary">
                <feather-icon icon="AlertCircleIcon" /> {{ $t('EmptyImageTypesCreateNewOne') }}
              </div>
              <div>
                <b-button
                  v-if="checkRequiredPermissions([$roles.SETTINGS])"
                  variant="primary"
                  size="sm"
                  :to="{ name: 'picture-types' }"
                  target="_blank"
                >
                  <feather-icon
                    icon="PlusIcon"
                    class="mr-25"
                  />
                  {{ $t('Add') }}
                </b-button>
              </div>
            </div>
          </b-card>
        </b-tab>

        <!-- Throws -->
        <b-tab :title="$t('offer.product.priceHistory')">
          <b-card>
            <h4 class="mb-1">
              {{ $t('offer.product.priceHistory') }}
            </h4>
            <!--    Pictures     -->
            <b-row>
              <b-col
                sm="12"
                md="12"
                lg="12"
              >
                <price-history
                  :prices="newProduct.shopProductHistories || []"
                />
              </b-col>
            </b-row>
          </b-card>
        </b-tab>

        <!-- Related Products -->
        <b-tab :title="$t('RelatedShopProducts')">
          <b-card>
            <b-form-group :label="$t('RelatedShopProducts')">
              <sw-select :disabled="isEdited">
                <v-select
                  v-model="newProduct.relatedShopProducts"
                  :options="relatedProducts"
                  multiple
                  class="w-100"
                  :filterable="false"
                  :get-option-label="product => product.id"
                  :selectable="product => product.id !== newProduct.id"
                  :disabled="isEdited"
                  @search="loadRelatedProducts"
                >
                  <template #option="item">
                    ({{ item.active ? $t('Active') : $t('InActive') }}) {{ item.translations[0].name }} - <span class="font-weight-bold">{{ item.offerPriceGrossEnd | priceFormat }} PLN</span>
                  </template>
                  <template #selected-option="item">
                    {{ item.translations[0].name }}
                  </template>
                  <template #no-options="{ search }">
                    <span v-if="search.length">
                      {{ $t('NoData') }}
                    </span>
                    <span v-else>
                      {{ $t('TypeToSearch') }}
                    </span>
                  </template>
                </v-select>
              </sw-select>
              <small
                v-if="!newProduct.category"
                class="text-danger"
              >{{ $t('ToRelateProductSelectCategory') }}</small>
            </b-form-group>
          </b-card>
          <!-- Products List -->
          <b-row class="related-products">
            <b-col
              v-for="product in newProduct.relatedShopProducts"
              :key="product.id"
              sm="12"
              md="6"
              lg="3"
            >
              <b-card class="d-flex product flex-column overflow-hidden">
                <product
                  :show-details="false"
                  :product="product"
                />
              </b-card>
            </b-col>
          </b-row>
        </b-tab>

        <!--    Marketing Plan     -->
        <b-tab :title="$t('MarketingPlan')" v-if="checkRequiredModule('marketingModule') && checkRequiredPermissions([$roles.MARKETING_PLAN_VIEW_ALL])">
          <marketing-plan :product.async="newProduct" :isEdited="isEdited" @remove="removeRange" />
        </b-tab>

        <!--    Loyalty Plan     -->
        <b-tab :title="$t('LoyaltyPlan')" v-if="checkRequiredModule('loyaltyModule') && checkRequiredPermissions([$roles.LOYALTY_PLAN_VIEW_ALL])">
          <loyalty-plan :product.async="newProduct" :isEdited="isEdited" />
        </b-tab>

        <b-tab :title="$t('MobileAppContent')" v-if="checkRequiredModule('clientMobileModule')">
          <b-button class="mb-50" size="sm" variant="primary" @click="openAddContentsModal">
              <feather-icon icon="PlusIcon" class="mr-25" />
              {{ $t('Add') }}
          </b-button>

          <b-row>
            <b-col sm="12" md="6" lg="4" v-for="(content, index) in selectedTranslationObject.contents" :key="`selected_content_${index}`">
              <b-card>
                <div class="d-flex flex-wrap justify-content-between">
                    <h6>{{ content.title }}</h6>
                    <div>
                        <b-button variant="flat-warning" @click="openAddContentsModal(content, index)">
                            <feather-icon icon="EditIcon" />
                        </b-button>
                        <b-button variant="flat-danger" class="ml-25" @click="deletedContent(index)">
                            <feather-icon icon="TrashIcon" />
                        </b-button>
                    </div>
                </div>
                <div v-html="content.body" />
              </b-card>
            </b-col>
          </b-row>
        </b-tab>
      </b-tabs>
      <!--      <b-button-->
      <!--        variant="primary"-->
      <!--        @click="pushToServer"-->
      <!--      >-->
      <!--        {{ $t('Save') }}-->
      <!--      </b-button>-->
    </validation-observer>
    <!--    Save Button    -->

    <!--    Modal: Create/Edit Category     -->
    <b-modal
      v-model="showCategoryModal"
      :title="$t('offer.category.new')"

      size="md"
      hide-footer
      no-close-on-backdrop
      @close="oldCategory = {}"
    >
      <category-modal :old-contract="oldCategory" />
    </b-modal>

    <!--    Modal: Create/Edit Subcategory     -->
    <b-modal
      v-model="showSubcategoryModal"
      :title="$t('offer.subcategory.new')"

      size="md"
      hide-footer
      no-close-on-backdrop
      @close="oldSubcategory = {}"
    >
      <subcategory-modal :old-subcontract="oldSubcategory" />
    </b-modal>

    <!--    Modal: Create/Edit Const Attributes     -->
    <b-modal
      v-model="showConstAttributesModal"
      :title="$t('offer.product.constantAttribute')"

      size="md"
      hide-footer
      no-close-on-backdrop
      @close="closeAttributeModal"
    >
        <sw-select :name="$t('offer.attribute.new')">
          <v-select
            v-model="selectedAttribute"
            :options="availableAttributes"
            label="id"
            @input="selectAttribute"
          >
            <template #option="item">
              {{ item.translations[0].name }}
            </template>
            <template #selected-option="item">
              {{ item.translations[0].name }}
            </template>
            <template #no-options>
              {{ $t('NoOptions') }}
            </template>
          </v-select>
        </sw-select>
      <!-- Type -->
      <div
        v-if="selectedAttribute"
        class="mt-1"
      >
        <b-form-group :label="$t('FieldType')">
          <b-form-input
            disabled
            :value="$t(`offer.attribute.types.${selectedAttribute.type}`)"
          />
        </b-form-group>
        <!-- Value -->
        <b-form-group :label="$t('Value')">
          <b-form-input
            v-if="['TEXT'].includes(selectedAttribute.type)"
            v-model="oldConstAttribute.value"
            type="text"
          />

          <sw-select v-else-if="['TIME', 'DATE'].includes(selectedAttribute.type)">
            <sw-select-fpr
              :is-empty="oldConstAttribute.value"
              @clear="oldConstAttribute.value = ''"
            >
              <flat-pickr
                v-model="oldConstAttribute.value"
                class="form-control"
                :config="{ locale: getCalendarLocale($i18n.locale), ...timeConfig(selectedAttribute.type) }"
              />
            </sw-select-fpr>
          </sw-select>

          <b-input-group
            v-else-if="['DIGITAL', 'PERCENTAGE', 'METRIC'].includes(selectedAttribute.type)"
            :append="selectedAttribute.type.type === 'PERCENTAGE' ? '%' : ''"
            class="input-group-merge"
          >
            <b-form-input
              v-model="oldConstAttribute.value"
              type="number"
            />
          </b-input-group>
          <sw-select v-else-if="['TEXT_ARRAY', 'TEXT_MULTIPLE_ARRAY'].includes(selectedAttribute.type)">
            <v-select
              v-model="oldConstAttribute.valueShopAttributeOptions"
              :multiple="selectedAttribute.type === 'TEXT_MULTIPLE_ARRAY'"
              :options="selectedAttribute.shopAttributeOptions"
              :get-option-label="attribute => attribute.id"
              lable="translations"
            >
              <template #option="item">
                {{ item.translations[0].name }}
              </template>
              <template #selected-option="item">
                {{ item.translations[0].name }}
              </template>
              <template #no-options>
                {{ $t('NoOptions') }}
              </template>
            </v-select>
          </sw-select>
        </b-form-group>
      </div>
      <!-- WWW -->
      <b-form-group :label="$tc('OfferWebsite')">
        <b-form-checkbox
          v-model="oldConstAttribute.visibleOfferWWW"
          name="check-button"
          variant="primary"
          switch
          inline
        />
      </b-form-group>
      <b-button
        class="mt-50"
        size="sm"
        @click="saveAttribute"
      >
        {{ $t('Save') }}
      </b-button>
    </b-modal>

    <b-modal
        v-model="showEditedContent"
        :title="editedContentIndex > -1 ? $t('Edit') : $t('Add')"
        size="md"
        hide-footer
        no-close-on-backdrop
        @close="showEditedContent = false; editedContent = null; editedContentIndex = -1"
    >
        <div v-if="editedContent">
            <b-alert show variant="primary">
              <div class="alert-body">
                {{ $tc('ContentForLanguage', 1, { language: typeof selectedLocale === 'string' ? $t(selectedLocale) : $t(selectedLocale.value) }) }}
              </div>
            </b-alert>
            <b-form-group :label="$t('Title')">
              <b-form-input
                v-model="editedContent.title"
              />
            </b-form-group>

            <b-form-checkbox switch variant="primary" v-model="htmlEditor">
                HTML
            </b-form-checkbox>
            <b-form-group :label="$t('Description')">
              <quill-editor
                v-if="htmlEditor"
                v-model="editedContent.body"
                :options="quillOptions"
              />
                <b-form-textarea v-model="editedContent.body" rows="5" v-else />
            </b-form-group>

            <b-button :disabled="!editedContent.title || !editedContent.body" variant="primary" @click="saveContent">
                {{ isEdited ? $t('Save') : $t('Add') }}
            </b-button>
        </div>
    </b-modal>
  </div>
</template>

<script>
import draggable from 'vuedraggable'
import {
  BAlert, BCard, BFormTextarea, BTab, BTable, BTabs, VBTooltip,
} from 'bootstrap-vue'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
// eslint-disable-next-line import/extensions
import DragDropUpload from '@/views/components/DragDropUpload'
import vSelect from 'vue-select'
import Cleave from 'vue-cleave-component'
import vatRate from '@/helpers/vatRates'
import { quillEditor } from 'vue-quill-editor'
import {
  CREATE_PRODUCT_STORAGE,
  DELETE_PRODUCT,
  DELETE_PRODUCT_ATTRIBUTES,
  DOWNLOAD_FILE,
  GET_ATTRIBUTE,
  GET_CATEGORY,
  GET_GROUPS,
  GET_PICTURE_TYPE,
  GET_PRODUCT,
  GET_PRODUCT_ATTRIBUTES,
  GET_PRODUCT_STORAGE,
  GET_PRODUCTS,
  GET_USER_CART,
  SAVE_PRODUCT,
  SAVE_PRODUCT_ATTRIBUTES,
  UPDATE_PRODUCT,
  UPDATE_PRODUCT_ATTRIBUTES,
  UPLOAD_FILES,
} from '@/@constants/mutations'
import { formatDate } from '@core/utils/filter'
import payloadKeysCutter from '@/helpers/keysCuter'
import PriceHistory from '@/views/offers/product/PriceHistory.vue'
import Product from '@/views/offers/shop/Product.vue'
import DocumentsList from '@/views/components/DocumentsList.vue'
import flatPickr from 'vue-flatpickr-component'
import { Polish as pl } from '@/libs/i18n/locales/flatpickr/pl'
import { english as en } from '@/libs/i18n/locales/flatpickr/en'
import subcategoryModal from '@/views/modals/SubcategoryModal.vue'
import constAttributeTable from '@/views/offers/product/ConstAttributeTable.vue'
// eslint-disable-next-line
import 'quill/dist/quill.core.css'
// eslint-disable-next-line
import 'quill/dist/quill.snow.css'
// eslint-disable-next-line import/order
import useVerticalLayout from '@core/layouts/layout-vertical/useVerticalLayout'
import { onUnmounted } from '@vue/composition-api'
import LoyaltyPlan from '@/pages/shop/product/LoyaltyPlan.vue'
import MarketingPlan from '@/pages/shop/product/MarketingPlan.vue'
import units from '@/helpers/productUnits'

export default {
  components: {
    MarketingPlan,
    LoyaltyPlan,
    DocumentsList,
    flatPickr,
    BFormTextarea,
    PriceHistory,
    BTable,
    quillEditor,
    // BTableSimple,
    // BThead,
    // BTbody,
    // BTr,
    // BTd,
    BAlert,
    BTabs,
    BTab,
    BCard,
    ValidationObserver,
    ValidationProvider,
    vSelect,
    DragDropUpload,
    Cleave,
    subcategoryModal,
    constAttributeTable,
    Product,
    draggable,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  props: {
    defaultCategory: {
      default: null,
      type: Object,
    },
    defaultSubcategory: {
      default: null,
      type: Object,
    },
  },
  emits: ['remove'],
  setup() {
    const { isMobile, resizeHandler } = useVerticalLayout()

    const perfectScrollbarSettings = {
      maxScrollbarLength: 60,
      wheelPropagation: false,
    }

    resizeHandler()
    window.addEventListener('resize', resizeHandler)
    onUnmounted(() => {
      window.removeEventListener('resize', resizeHandler)
    })

    return {
      perfectScrollbarSettings,
      isMobile,
    }
  },
  data: () => ({
    selectedLocale: 'pl_pl',
    locales: [
      { value: 'pl_pl', label: 'pl_pl' },
      { value: 'en_uk', label: 'en_uk' },
      { value: 'de_de', label: 'de_de' },
      { value: 'ar_dz', label: 'ar_dz', requiredDomain: 'lasland' },
      { value: 'ko_kr', label: 'ko_kr', requiredDomain: 'lasland' },
      { value: 'zh_cn', label: 'zh_cn', requiredDomain: 'lasland' },
    ],
    timeRange: '',
    attributes: [],
    attributesToDelete: [],
    pictures: [],
    pictureTypes: [],
    documents: [],
    webUrl: '',
    units,
    newProduct: {
      color: '#7267f0',
      translations: [
        {
          name: '', description: '', locale: 'pl_pl', contents: [],
        },
      ],
      code: '',
      userGroup: '',
      vat: 23,
      category: '',
      shopCategories: '',
      active: true,
      offerPrice: 0,
      offerPriceGrossEnd: 0,
      // transactionPrice: 0,
      // promotionPriceGrossEnd: 0,
      offerPriceWithDiscount: 0,
      priceMultiplier: 0,
      // priceMultiplierFinal: 0,
      shopProductDiscounts: [
        {
          value: '', type: true, startAt: '', endAt: '',
        },
      ],
      shopProductHistories: [],
      relatedShopProducts: [],
      shopProductLoyaltyPlans: [],
      shopProductMarketingPlans: [],
    },
    // quantity: [],

    constAttributeFields: [],

    vatRate: (vatRate)(),
    isEdited: true,

    showCategoryModal: false,
    showSubcategoryModal: false,

    showConstAttributesModal: false,
    showVarAttributesModal: false,

    olgGroup: {},
    oldCategory: {},
    oldSubcategory: {},

    quillOptions: {
      placeholder: '',
      theme: 'snow',
    },
    flatPickerConfig: {
      mode: 'range',
      enableTime: true,
      dateFormat: 'Y-m-d H:i',
      time_24hr: true,
      locale: [pl, en],
    },
    // Attribute
    oldConstAttribute: {
      value: '',
      visibleOfferWWW: false,
      shopAttribute: {},
      valueShopAttributeOptions: [],
      active: true,
    },
    oldConstAttributeIndex: -1,

    // Selected
    selectedAttribute: null,
    // Data
    categories: [],
    subcategories: [],
    groups: [],
    baseAttributes: [],
    availableAttributes: [],
    relatedProducts: [],
    // Flags
    cleaveOptions: {
      number: {
        delimiter: ' ',
        numeral: true,
        numeralThousandsGroupStyle: 'thousand',
      },
      numberPositive: {
        delimiter: ' ',
        numeral: true,
        numeralThousandsGroupStyle: 'thousand',
        numeralPositiveOnly: true,
      },
    },
    sorter: {
      sortBy: 'createdAt',
      sortDesc: true,
    },
    htmlEditor: false,
    storagePagination: {
      perPage: 25,
      currentPage: 1,
      totalRecords: 1,
    },
    storageFields: [
      { key: 'note', label: 'Note', visible: true },
      { key: 'amount', label: 'Offer.Amount', visible: true },
      { key: 'files', label: 'Documents', visible: true },
      { key: 'createdAt', label: 'CreatedAt', visible: true },
      { key: 'createdBy', label: 'CreatedBy', visible: true },
    ],
    productStorageItems: [],
    showStorageModal: false,
    storageItem: {
      removeFromStorage: false,
      note: '',
      files: [],
      amount: '',
    },
    shopProductStoragesSum: 0,
    loading: false,
    storageLoading: false,
    saveLoading: false,
    editedContentIndex: -1,
    editedContent: false,
    showEditedContent: null,

    TESTmarketingPlans: [{
      name: 'Plany górą',
      netCommissions: [
        {
          price: 1200,
          currency: 'PLN',
        },
      ],
      minCost: {
        val: 340,
        currency: 'PLN',
      },
    }],
    TESTloyaltyPlans: [{
      name: 'Plany duże i te małe',
      buy: 4,
      sell: 10,
    }],
  }),
  computed: {
    selectedTranslationObject() {
      const { selectedLocale } = this

      return this.newProduct.translations.find(({ locale }) => locale === selectedLocale) || {}
    },
    filteredLocales() {
      return this.locales.filter(locale => !locale.requiredDomain || window.location.href.includes(locale.requiredDomain))
    },
    categoriesByGroup() {
      const groupId = this.newProduct.userGroup?.id || this.newProduct.userGroup
      if (groupId) {
        return this.categories.filter(cat => {
          if (cat.userGroups?.find(group => group.id === groupId)) return cat

          return false
        })
      }

      return this.categories
    },
    currentProductStorageAmount() {
      const current = this.shopProductStoragesSum
      const newItemsSum = this.productStorageItems.filter(item => !item?.id).reduce((a, b) => +a + parseFloat(b.amount), 0)
      return current + newItemsSum
    },
    calculatePromotionFullPrice() {
      const price = this.calculateBasePriceBySwitch().toFixed(2)
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.setPrice('offerPriceWithDiscount', price)
      return price
    },
    calculatePromotionPrice() {
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      return (this.calculateBasePriceBySwitch() * this.newProduct.priceMultiplier).toFixed(2)
    },
    calculatePromotionGrossPrice() {
      return (this.calculateBasePriceBySwitch() * ((this.newProduct.vat / 100) + 1)).toFixed(2)
    },
    calculatePromotionFullGrossPrice() {
      const price = this.calculatePrice(this.calculateBasePriceBySwitch()).toFixed(2)
      this.setPrice('priceWithDiscountGrossEnd', price)
      return price
    },
    calculateOfferGross() {
      const price = this.calculatePrice(this.newProduct.offerPrice).toFixed(2)
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.setPrice('offerPriceGrossEnd', price)
      this.setPrice('offerPriceWithDiscount', this.calculateBasePriceBySwitch().toFixed(2))
      this.setPrice('priceWithDiscountGrossEnd', this.calculatePrice(this.calculateBasePriceBySwitch()).toFixed(2))
      if (this.$store.getters['system/getSettings'].removeGrossEnds) {
        return Number(price).toFixed(0)
      }
      return price
    },
    calculateMinimalGross() {
      const price = this.calculatePrice(this.newProduct.minimumPrice).toFixed(2)
      return price
    },
    calculateTransactionGross() {
      const price = this.calculatePrice(this.newProduct.transactionPrice).toFixed(2)
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      return price
    },
    allowEditButton() {
      return this.$route.name !== 'productEdit'
    },
    locale() {
      return this.$i18n.locale
    },
  },
  watch: {
    $route: {
      deep: true,
      handler(newValue) {
        if (newValue === 'productEdit') this.isEdited = false
        else this.isEdited = true
      },
    },
    'newProduct.relatedShopProducts': {
      deep: false,
      handler() {
        this.downloadRelatedProductsPreview()
      },
    },
    '$route.params.productId': {
      deep: true,
      handler(newValue, oldValue) {
        if (newValue !== oldValue) this.loadData()
      },
    },
  },
  async mounted() {
    if (!this.allowEditButton) this.isEdited = false

    await this.loadCategories()

    this.loadData()
  },
  methods: {
    saveContent() {
      const isEdited = this.editedContentIndex > -1
      if (isEdited) {
        this.selectedTranslationObject.contents[this.editedContentIndex] = this.editedContent
      } else {
        this.selectedTranslationObject.contents.push(this.editedContent)
      }

      this.editedContent = null
      this.editedContentIndex = -1
      this.showEditedContent = false
    },
    deletedContent(index) {
      this.$delete(this.selectedTranslationObject.contents, index)
    },
    openAddContentsModal(editedContent = null, index = -1) {
      this.editedContent = editedContent?.title ? { ...editedContent } : {
        title: '',
        body: '',
      }
      this.editedContentIndex = index

      this.showEditedContent = true
    },
    checkAvailableLocale(object) {
      if (!object) return null

      const value = object?.value || object || 'pl_pl'

      const isInTranslations = Boolean(this.newProduct.translations.find(({ locale }) => value === locale))

      if (!isInTranslations) {
        const translation = {
          name: '',
          description: '',
          locale: value,
          contents: [],
        }
        this.newProduct.translations.push(translation)

        return translation
      }
      return null
    },
    setTranslationValue(value, key) {
      const { selectedLocale } = this

      const translationIndex = this.newProduct.translations.findIndex(({ locale }) => selectedLocale === locale)
      if (translationIndex > -1) {
        this.$set(this.newProduct.translations[translationIndex], key, value)
      }
    },
    async loadImage(file) {
      if (!file.isImageLoaded) {
        this.$store.dispatch(`uploader/${DOWNLOAD_FILE}`, { fileToken: file.token, filename: file.name })
          .then(fileRes => {
            const { data, filename } = fileRes
            // eslint-disable-next-line
            fetch(`data:${data.type};base64,${data.file}`)
              .then(test => test.blob())
              .then(blob => {
                const blobFile = new File([blob], filename, { type: data.type })
                // eslint-disable-next-line
                file.preview = URL.createObjectURL(blobFile)
                this.$set(this.product.pictures, 0, file)
              })
          })
      }

      // eslint-disable-next-line
      file.isImageLoaded = true
    },
    closeModal() {
      this.storageItem = {
        amount: 0, files: [], note: '', removeFromStorage: false,
      }
      this.showStorageModal = false
    },
    formatDate,
    async addToStorage() {
      const item = this.storageItem

      if (item.removeFromStorage) item.amount *= -1

      this.productStorageItems.push(item)

      this.closeModal()
    },
    setPrice(field, price) {
      this.$set(this.newProduct, field, parseFloat(price))
    },
    removeRange(planIndex, rangeIndex) {
      this.$delete(this.newProduct.shopProductMarketingPlans[planIndex].shopProductMarketingPlanCommissions, rangeIndex)
    },
    deleteProduct() {
      const id = this.$route?.params?.productId
      if (id) {
        this.showAlert('warning', this.$i18n.t('alerts.AreYouSure'), this.$i18n.t('alerts.WontbeAble'))
          .then(() => {
            this.$store.dispatch(`products/${DELETE_PRODUCT}`, id)
              .then(() => {
                this.showToast('success', this.$i18n.t('RemovedSuccessfully'))
                this.$router.push({ name: 'shop' })
              })
              .catch(err => {
                this.showToast('danger', this.$i18n.t(`errors.${err?.response?.data?.message || 'UNKNOWN_BUG'}`), err)
              })
          })
      }
    },
    loadRelatedProducts(search, loading) {
      if (search.length) {
        loading(true)
        this.$store.dispatch(`products/${GET_PRODUCTS}`, { search, filters: { category: typeof this.newProduct.category === 'object' ? this.newProduct.category?.id : this.newProduct.category } })
          .then(res => {
            this.relatedProducts = res.data.items
            loading(false)
          })
          .catch(err => {
            this.showToast('danger', this.$i18n.t(`errors.${err?.response?.data?.message || 'UNKNOWN_BUG'}`), err)
            loading(false)
          })
      }
    },
    calculateBasePriceBySwitch() {
      const discount = this.newProduct.shopProductDiscounts[0]
      if (discount?.type) {
        return this.newProduct.offerPrice - discount.value
      }
      return this.newProduct.offerPrice - (this.newProduct.offerPrice * ((discount?.value || 0) / 100))
    },
    onChangeDate(value) {
      const time = value.dateRange()
      // eslint-disable-next-line prefer-destructuring
      if (time.length === 1) this.newProduct.shopProductDiscounts[0].startAt = time[0]
      if (time.length === 2) {
        // eslint-disable-next-line prefer-destructuring
        this.newProduct.shopProductDiscounts[0].startAt = time[0]
        // eslint-disable-next-line prefer-destructuring
        this.newProduct.shopProductDiscounts[0].endAt = time[1]
      }
    },
    calculatePrice(fromPrice) {
      const price = fromPrice * (1 + (this.newProduct.vat / 100))

      return price
    },
    loadAndSetBaseAttributes() {
      this.saveLoading = false
      if (this.attributes.length === 0 && this.$route.name === 'productEdit') {
        this.baseAttributes.forEach(({
          base, active, translations, type, shopAttributeOptions, id,
        }) => {
          this.attributes.push({
            value: '',
            visibleOfferWWW: true,
            shopAttribute: {
              base, active, translations, type, shopAttributeOptions, id,
            },
            valueShopAttributeOptions: [],
            active: true,
          })
        })
      }
    },
    timeConfig(type) {
      return type === 'TIME' ? { enableTime: true, noCalendar: true, dateFormat: 'H:i' } : {}
    },
    loadData() {
      this.loading = true
      Promise.all([this.loadCategories(), this.loadGroups(), this.loadAttributes(), this.loadPictureTypes()])
        .then(() => {
          if (this.$route?.params?.productId) {
            this.loadProductData()
          } else {
            this.loadAndSetBaseAttributes()
            this.loading = false
          }
        })
        .catch(err => {
          this.loading = false
          this.showToast('danger', this.$i18n.t(`errors.${err?.response?.data?.message || 'UNKNOWN_BUG'}`), err)
        })

      if (this.defaultCategory && this.defaultSubcategory) {
        this.selectCategory(this.defaultCategory)
        this.selectSubCategory(this.defaultSubcategory)
      } if (this.defaultCategory) {
        this.selectCategory(this.defaultCategory)
      }
    },
    loadStorage(id) {
      this.storageLoading = true
      // eslint-disable-next-line no-underscore-dangle
      const _id = id || this.$route?.params?.productId
      return new Promise((resolve, reject) => {
        this.$store.dispatch(`products/${GET_PRODUCT_STORAGE}`, { productId: _id, pagination: this.storagePagination })
          .then(res => {
            this.productStorageItems = res.data.items
            this.storagePagination.totalRecords = res.data.totalItemCount
            resolve()
            this.storageLoading = false
          })
          .catch(err => {
            this.showToast('danger', this.$i18n.t(`errors.${err?.response?.data?.message || 'UNKNOWN_BUG'}`), err)
            reject()
            this.storageLoading = false
          })
      })
    },
    loadProductData() {
      const id = this.$route?.params?.productId
      if (id) {
        this.$store.dispatch(`products/${GET_PRODUCT}`, id)
          .then(res => {
            this.saveLoading = false
            const { item } = res.data
            Object.keys(item).forEach(key => {
              this.newProduct[key] = item[key]

              if (key === 'translations') {
                this.newProduct[key] = item[key].map(t => ({ ...t, contents: (t.contents ?? []).map(e => ({ ...e, body: e.body.replaceAll('\\"', '"') })), webUrl: t?.webUrl || item?.webUrl || '' }))
              }
              if (key === 'color') this.newProduct.color = item[key] || '#7267f0'
              if (key === 'shopProductStoragesSum') this.shopProductStoragesSum = item.shopProductStoragesSum
              if (key === 'shopProductPictures') {
                // this[key] = item[key]
                this.downloadFilesPreview(item[key], item[key].map(picture => picture.file), true)
              }
              if (key === 'documents') {
                this[key] = item[key]
                this.downloadFilesPreview('documents', item[key])
              }
              if (key === 'shopCategories') {
                if (item[key][0]?.userGroups) {
                  // eslint-disable-next-line prefer-destructuring
                  this.newProduct.userGroup = item[key][0].userGroups[0]
                  this.selectGroup(item[key][0].userGroups[0])
                }
                this.selectCategory(item[key][0])
                this.selectSubCategory(item[key][1])
              }
              if (key === 'shopProductDiscounts') {
                if (item[key].length) {
                  this.newProduct.shopProductDiscounts[0].type = item[key][0].type === 'NETTO_VALUE'
                  this.newProduct.shopProductDiscounts[0].value = item[key][0].value
                  const start = item[key][0].startAt.date.split('.')[0]
                  const end = item[key][0].endAt.date.split('.')[0]
                  this.timeRange = `${start} do ${end}`
                  this.onChangeDate(`${start} do ${end}`)
                } else {
                  this.newProduct.shopProductDiscounts = [
                    {
                      value: '', type: true, startAt: '', endAt: '',
                    },
                  ]
                }
              }
            })
            this.loadAttributesData(id)
            this.assignPicturesToTypes(this.newProduct.shopProductPictures)
            this.loadStorage(id)
          })
          .catch(err => {
            this.showToast('danger', this.$i18n.t(`errors.${err?.response?.data?.message || 'UNKNOWN_BUG'}`), err)
          })
      }
    },
    assignPicturesToTypes(pictureObjects) {
      pictureObjects.forEach(({
        file, id, position, shopProductPictureType,
      }, index) => {
        const typeIndex = this.pictureTypes.findIndex(type => type.id === shopProductPictureType.id)

        if (typeIndex !== -1) {
          const pos = position !== null ? position : index

          this.pictureTypes[typeIndex].pictures.push({ ...file, id, position: pos })
        }
      })

      /*    It is such a splendid sorting algorithm for beautiful Product Photos    */
      this.pictureTypes.map(el => el.pictures.sort((a, b) => a.position - b.position))
    },
    loadPictureTypes() {
      return new Promise((resolve, reject) => {
        this.$store.dispatch(`productPictures/${GET_PICTURE_TYPE}`)
          .then(res => {
            resolve(res)
            const types = res.data.items.map(type => ({
              ...type,
              pictures: [],
            }))
            this.$set(this, 'pictureTypes', types)
          })
          .catch(err => {
            this.showToast('danger', this.$i18n.t(`errors.${err?.response?.data?.message || 'UNKNOWN_BUG'}`), err)
            reject(err)
          })
      })
    },
    loadAttributesData(productId) {
      this.$store.dispatch(`products/${GET_PRODUCT_ATTRIBUTES}`, productId)
        .then(res => {
          this.attributes = res.data.items

          this.sortAttr()

          this.loading = false
        })
        .catch(err => {
          this.showToast('danger', this.$i18n.t(`errors.${err?.response?.data?.message || 'UNKNOWN_BUG'}`), err)
          this.loading = false
        })
    },
    b64toBlob(b64Data, contentType = '', sliceSize = 512) {
      const byteCharacters = atob(b64Data)
      const byteArrays = []

      for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
        const slice = byteCharacters.slice(offset, offset + sliceSize)

        const byteNumbers = new Array(slice.length)
        // eslint-disable-next-line no-plusplus
        for (let i = 0; i < slice.length; i++) {
          byteNumbers[i] = slice.charCodeAt(i)
        }

        const byteArray = new Uint8Array(byteNumbers)
        byteArrays.push(byteArray)
      }

      const blob = new Blob(byteArrays, { type: contentType })
      return blob
    },
    downloadFilesPreview(place, items, t = false) {
      items.forEach((file, index) => {
        this.$store.dispatch(`uploader/${DOWNLOAD_FILE}`, { fileToken: file.token, filename: file.name })
          .then(fileRes => {
            const { data } = fileRes
            // eslint-disable-next-line
            // fetch(`data:${data.type};base64,${data.file}`)
            //   .then(test => test.blob())
            //   .then(blob => {
            //     const blobFile = new File([blob], filename, { type: data.type })
            //     // eslint-disable-next-line
            //     file.preview = URL.createObjectURL(blobFile)
            //     this.$set(this[place], index, file)
            //   })
            const item = this.b64toBlob(data.file, data.type)
            // eslint-disable-next-line
            try {
              // eslint-disable-next-line
              file.preview = URL.createObjectURL(item)
            } catch (err) {
              this.showToast('danger', this.$i18n.t(`errors.${err?.response?.data?.message || 'UNKNOWN_BUG'}`), err)
            } finally {
              if (!t) this.$set(this[place], index, file)
              else this.$set(place, index, file)
            }
          })
      })
    },
    ///    Get Groups     ///
    loadGroups() {
      return new Promise((resolve, reject) => {
        this.$store.dispatch(`groups/${GET_GROUPS}`)
          .then(res => {
            this.groups = res.data.items
            resolve()
          })
          .catch(err => {
            this.showToast('danger', this.$i18n.t(`errors.${err?.response?.data?.message || 'UNKNOWN_BUG'}`), err)
            reject(err)
          })
      })
    },
    selectGroup(group) {
      this.newProduct.category = ''
      this.newProduct.shopCategories = ''
      this.subcategories = []
      if (group) this.loadCategories()
    },
    ///    Get Attributes ///
    loadAttributes() {
      return new Promise((resolve, reject) => {
        this.$store.dispatch(`attributes/${GET_ATTRIBUTE}`)
          .then(res => {
            this.baseAttributes = res.data.items?.filter(item => item.base && item.active) || []
            this.availableAttributes = res.data.items?.filter(item => item.active) || []

            resolve()
          })
          .catch(err => {
            this.showToast('danger', this.$i18n.t(`errors.${err?.response?.data?.message || 'UNKNOWN_BUG'}`), err)
            reject(err)
          })
      })
    },
    ///    Open Attribute Modal ///
    closeAttributeModal() {
      this.showConstAttributesModal = false
      this.selectedAttribute = null
      this.oldConstAttribute = {
        value: '',
        visibleOfferWWW: false,
        shopAttribute: {},
        valueShopAttributeOptions: [],
        active: true,
      }
      this.oldConstAttributeIndex = -1
    },
    sortAttr() {
      this.attributes.forEach((attr, i) => { this.attributes[i].position = attr.position === null || attr.position === undefined ? i : attr.position })

      this.attributes.sort((a, b) => a.position - b.position)
    },
    ///    Open Attribute Modal ///
    saveAttribute() {
      const attributeToAdd = JSON.parse(JSON.stringify(this.oldConstAttribute))

      if (attributeToAdd.position === null || attributeToAdd.position === undefined) attributeToAdd.position = this.attributes.length

      if (['TEXT_ARRAY', 'TEXT_MULTIPLE_ARRAY'].includes(attributeToAdd?.shopAttribute?.type)) attributeToAdd.value = ''
      else attributeToAdd.valueShopAttributeOptions = []

      if (this.oldConstAttributeIndex !== -1) {
        this.$set(this.attributes, this.oldConstAttributeIndex, attributeToAdd)
      } else {
        this.attributes.push(JSON.parse(JSON.stringify(attributeToAdd)))
      }
      this.closeAttributeModal()
    },
    ///    Open Attribute Modal ///
    openAttributeModal(attribute, index = -1) {
      this.selectedAttribute = attribute.shopAttribute
      this.showConstAttributesModal = true
      this.oldConstAttribute = JSON.parse(JSON.stringify(attribute))
      this.oldConstAttributeIndex = index
    },
    ///    Open Attribute Modal ///
    deleteAttributeModal(index) {
      const product = this.attributes[index]

      if (product.id) this.attributesToDelete.push(product.id)

      this.attributes.splice(index, 1)

      this.attributes.forEach((attr, i) => {
        if (attr.position !== i) this.attributes[i].position = i
      })

      this.sortAttr()
    },
    ///    Select Attribute ///
    selectAttribute(attribute) {
      this.selectedAttribute = attribute
      this.oldConstAttribute.shopAttribute = attribute
    },
    ///    Get Categories ///
    loadCategories() {
      return new Promise((resolve, reject) => {
        // (typeof this.newProduct.userGroup === 'object' ? this.newProduct.userGroup.id : this.newProduct.userGroup)
        this.$store.dispatch(`categories/${GET_CATEGORY}`)
          .then(res => {
            this.categories = res.data.items
            resolve()
          })
          .catch(err => {
            this.showToast('danger', this.$i18n.t(`errors.${err?.response?.data?.message || 'UNKNOWN_BUG'}`), err)
            reject(err)
          })
      })
    },
    selectCategory(categoryObject) {
      if (categoryObject) {
        this.subcategories = categoryObject.children
        this.newProduct.category = categoryObject
      } else {
        this.newProduct.category = ''
        this.newProduct.shopCategories = ''
        this.subcategories = []
      }
    },
    selectSubCategory(subcategoryObject) {
      if (subcategoryObject) {
        this.newProduct.shopCategories = subcategoryObject
      } else {
        this.newProduct.shopCategories = ''
      }
    },
    ///    Change Files    ///
    changeFiles(place, files) {
      files.forEach(file => {
        // eslint-disable-next-line
        file.preview = URL.createObjectURL(file)
      })
      this[place].push(...files)
    },
    ///    Change Files    ///
    changePictureFiles(place, typeId, files) {
      files.forEach(file => {
        // eslint-disable-next-line
        file.preview = URL.createObjectURL(file)
      })
      const picturesIndex = this.pictureTypes.findIndex(type => type.id === typeId)

      if (picturesIndex !== -1) {
        this.pictureTypes[picturesIndex].pictures.push(...files)
      }
    },
    changeStorageFiles(files) {
      files.forEach(file => {
        // eslint-disable-next-line
        file.preview = URL.createObjectURL(file)
      })

      this.storageItem.files = files
    },
    deleteImage(index) {
      this.$delete(this.pictures, index)
    },
    deleteThrows(index) {
      this.$delete(this.documents, index)
    },

    /* eslint-disable */
    async pushToServer() {
      const isValid = await this.$refs.observer.validate()
      if (!isValid) return

      this.saveLoading = true

      const objectFields = ['shopCategories', 'userGroup', 'category']
      const productCopy = JSON.parse(JSON.stringify(this.newProduct))

      // const picturesToUpload = this.pictures.filter(file => !file.token)
      const documentsToUpload = this.documents.filter(file => !file.token)
      // productCopy.pictures = this.pictures.filter(file => file.token)
      productCopy.documents = this.documents.filter(file => file.token)

      productCopy.offerPrice = Number(parseFloat(productCopy.offerPrice).toFixed(2))

      this.pictureTypes.forEach((type, iType) => {
        type.pictures.forEach((_, iPicture) => {
          this.pictureTypes[iType].pictures[iPicture].position = iPicture
        })
      })

      const shopProductImages = this.pictureTypes.map(({ id, pictures }) => ({
        shopProductPictureType: id,
        pictures: pictures.filter(file => file.token),
        picturesToUpload: pictures.filter(file => !file.token),
      }))

      const areImagesUploaded = await Promise.all(
        shopProductImages
          .filter(item => item.picturesToUpload.length)
          .map(productImage => this.uploadFiles(productImage.picturesToUpload)),
      )
        .then(res => {
          shopProductImages
            .filter(item => item.picturesToUpload.length)
            .forEach((item, index) => {
              // eslint-disable-next-line
              item.pictures.push(...res[index])
            })
          return true
        })
        .catch(() => false)

      const images = []
      shopProductImages.forEach(item => {
        item.pictures.forEach((picture, index) => {
          const ob = {
            shopProductPictureType: item.shopProductPictureType,
            file: picture?.token ? picture.token : picture,
            position: picture.position !== null && picture.position !== undefined ? picture.position : index,
          }
          if (picture?.id) {
            ob.id = picture.id
          }

          images.push(ob)
        })
      })

      if (areImagesUploaded) {
        objectFields.forEach(field => {
          productCopy[field] = (typeof productCopy[field] === 'object' ? productCopy[field]?.id : productCopy[field]) || null
        })

        if (productCopy.relatedShopProducts.length) {
          productCopy.relatedShopProducts.forEach((product, index) => {
            productCopy.relatedShopProducts[index] = typeof product === 'object' ? product.id : product
          })
        } else productCopy.relatedShopProducts = []

        productCopy.shopProductDiscounts[0].type = !productCopy.shopProductDiscounts[0].type ? 'NETTO_PERCENT' : 'NETTO_VALUE'
        productCopy.shopProductDiscounts[0].value = parseFloat(productCopy.shopProductDiscounts[0].value)

        if (!productCopy.shopProductDiscounts[0].value) {
          productCopy.shopProductDiscounts = []
        }

        productCopy.shopCategories = [productCopy.category, productCopy.shopCategories].filter(Boolean)

        Object.keys(productCopy).forEach(key => {
          if (['priceMultiplier', 'priceMultiplierFinal', 'vat'].includes(key)) productCopy[key] = parseFloat(productCopy[key])
        })

        // Plans
        productCopy.shopProductLoyaltyPlans = productCopy.shopProductLoyaltyPlans.map(plan => ({
          ...plan,
          pointsReward: parseFloat(plan.pointsReward),
          pointsPrice: parseFloat(plan.pointsPrice),
          loyaltyPlan: this.getObjectId(plan.loyaltyPlan),
        }))

        // Plans
        productCopy.shopProductMarketingPlans = productCopy.shopProductMarketingPlans.map(plan => ({
          ...plan,
          minimumProductPrice: parseFloat(plan.minimumProductPrice),
          shopProductMarketingPlanCommissions: plan.shopProductMarketingPlanCommissions.map((commission, index) => ({
            ...commission,
            value: parseFloat(commission.value),
            productAmountMax: parseFloat(commission.productAmountMax),
            productAmountMin: parseFloat(commission.productAmountMin),
          })),
          marketingPlan: this.getObjectId(plan.marketingPlan),
        }))

        payloadKeysCutter(productCopy, ['shopProductAttributes', 'shopProductHistories'])

        // eslint-disable-next-line no-unreachable
        Promise.all([this.uploadFiles(documentsToUpload)])
          .then(res => {
            productCopy.shopProductPictures = images
            productCopy.documents = [...productCopy.documents.map(file => (file?.token ? file.token : file)), ...res[0]]

            const productId = this.$route?.params?.productId
            let action = SAVE_PRODUCT
            if (productId) {
              action = UPDATE_PRODUCT
              productCopy.id = productId
            }

            this.$store.dispatch(`products/${action}`, productCopy)
              .then(product => {
                  if (this.checkRequiredModule('offerModule')) {
                      this.$store.dispatch(`cart/${GET_USER_CART}`)
                  }
                const id = product.data?.items[0]?.id
                if (id) {
                  this.saveAttributes(id)
                  this.saveStorage(id)
                  if (this.$route.name !== 'productView') this.$router.push({ name: 'productView', params: { productId: id } })
                }
              })
          })
          .catch(err => {
            this.showToast('danger', this.$i18n.t(`errors.${err?.response?.data?.message || 'UNKNOWN_BUG'}`), err)
          })
      }
    },
    saveStorage(productId) {
      const newStorageItems = this.productStorageItems.filter(item => !item?.id)
      Promise.all(newStorageItems.map(item => this.uploadFiles(item.files)))
        .then(res => {
          const itemsToPush = []

          newStorageItems.forEach((item, index) => {
            itemsToPush.push({
              ...item,
              amount: parseFloat(item.amount),
              files: res[index],
            })
          })

          if (itemsToPush.length) {
            this.$store.dispatch(`products/${CREATE_PRODUCT_STORAGE}`, { productId, storageItems: itemsToPush })
              .then(() => {
                this.loadStorage(productId)
              })
          }
        })
    },
    // Push Attributes To server
    async saveAttributes(productId) {
      const attributesCopy = JSON.parse(JSON.stringify(this.attributes))
      const attributesToDeleteCopy = JSON.parse(JSON.stringify(this.attributesToDelete))

      attributesCopy.forEach(item => {
        // eslint-disable-next-line
        item.shopAttribute = typeof item.shopAttribute === 'object' ? item.shopAttribute.id : item.shopAttribute
        // eslint-disable-next-line,no-nested-ternary
        item.valueShopAttributeOptions = Array.isArray(item.valueShopAttributeOptions)
          ? item.valueShopAttributeOptions.map(value => (typeof value === 'string' ? value : value.id))
          : [typeof item.valueShopAttributeOptions === 'object' ? item.valueShopAttributeOptions.id : item.valueShopAttributeOptions]
      })

      payloadKeysCutter(attributesCopy, ['shopProduct'])
      const attributesToPush = attributesCopy.filter(attr => !attr.id) || []
      const attributesToUpdate = attributesCopy.filter(attr => attr.id) || []
      await Promise.all([this.pushAttributes(attributesToPush, productId), this.deleteAttribute(attributesToDeleteCopy, productId), this.updateAttributes(attributesToUpdate, productId)])
        .then(() => {
          this.loadData()
          const update = this.$route?.params?.productId
          if (update) {
            this.showToast('success', this.$i18n.t('ProductUpdatedSuccessfully'))
          } else {
            this.showToast('success', this.$i18n.t('ProductCreatedSuccessfully'))
          }
        })
        .catch(err => {
          this.showToast('danger', this.$i18n.t(`errors.${err?.response?.data?.message || 'UNKNOWN_BUG'}`), err)
        })
    },
    pushAttributes(attributes, productId) {
      return new Promise((resolve, reject) => {
        this.$store.dispatch(`products/${SAVE_PRODUCT_ATTRIBUTES}`, { id: productId, attributes })
          .then(res => {
            resolve(res)
          })
          .catch(err => {
            this.showToast('danger', this.$i18n.t(`errors.${err?.response?.data?.message || 'UNKNOWN_BUG'}`), err)
            reject(err)
          })
      })
    },
    deleteAttribute(attributes, productId) {
      return new Promise((resolve, reject) => {
        this.$store.dispatch(`products/${DELETE_PRODUCT_ATTRIBUTES}`, { id: productId, attributesIdList: attributes })
          .then(res => {
            resolve(res)
          })
          .catch(err => {
            this.showToast('danger', this.$i18n.t(`errors.${err?.response?.data?.message || 'UNKNOWN_BUG'}`), err)
            reject(err)
          })
      })
    },
    updateAttributes(attributes, productId) {
      return new Promise((resolve, reject) => {
        this.$store.dispatch(`products/${UPDATE_PRODUCT_ATTRIBUTES}`, { id: productId, attributes })
          .then(res => {
            resolve(res)
          })
          .catch(err => {
            this.showToast('danger', this.$i18n.t(`errors.${err?.response?.data?.message || 'UNKNOWN_BUG'}`), err)
            reject(err)
          })
      })
    },
    // Push files to server
    uploadFiles(files) {
      return new Promise((resolve, reject) => {
        const payload = { uploadedFiles: files, type: 'building' }
        this.$store.dispatch(`uploader/${UPLOAD_FILES}`, payload)
          .then(res => {
            resolve(res.files)
          })
          .catch(err => {
            this.showToast('danger', this.$i18n.t(`errors.${err?.response?.data?.message || 'UNKNOWN_BUG'}`), err)
            reject()
          })
      })
    },
    downloadRelatedProductsPreview() {
      this.newProduct.relatedShopProducts.forEach((item, productIndex) => {
        this.newProduct.relatedShopProducts[productIndex].pictures = []
        const picutes = item?.shopProductPictures ? item?.shopProductPictures.map(picture => picture.file) : []
        picutes.forEach((file, index) => {
          this.newProduct.relatedShopProducts[productIndex].pictures.push(file)

          this.$store.dispatch(`uploader/${DOWNLOAD_FILE}`, { fileToken: file.token, filename: file.name })
            .then(fileRes => {
              const { data, filename } = fileRes
              // eslint-disable-next-line
              fetch(`data:${data.type};base64,${data.file}`)
                .then(test => test.blob())
                .then(blob => {
                  const blobFile = new File([blob], filename, { type: data.type })
                  // eslint-disable-next-line
                  file.preview = URL.createObjectURL(blobFile)
                  // this.newProduct.relatedShopProducts[productIndex].pictures.push(file)
                  this.$set(this.newProduct.relatedShopProducts[productIndex].pictures, index, file)
                })
            })
        })
      })
    },
  },
}
</script>

<style lang="scss">
.ql-editor {
  min-height: 14rem !important;
}
</style>

<style lang="scss" scoped>
.cardTable {
  ///    Change padding of Card    ///
  .card-body {
    padding: 0 !important;

    .table-responsive {
      margin: 0 !important;
    }
  }
}
</style>

<style lang="scss">
.related-products {
  ///    Change padding of Card    ///
  .card-body {
    padding: 0 !important;
  }
}
</style>

<style lang="scss" scoped>
.image-overlay-container {
  position: relative;
  height: 250px;
  width: 100%;
  overflow: hidden;

  .image-overlay {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    height: 100%;
    width: 100%;
    opacity: 0;
    transition: .5s ease;
    background-color: rgba(115, 103, 240, .7);
  }

  .image-overlay.danger {
    background-color: rgba(220, 53, 69, .7);
  }
}
</style>
